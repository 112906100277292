'use client';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { Button } from '@/app/ui/common/button';

export default function Error({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  useEffect(() => {
    // Optionally log the error to an error reporting service
    console.error(error);
  }, [error]);
  const router = useRouter();

  const goBack = () => {
    router.back();
  };

  const buttonClasses = "flex-1 w-full sm:w-[240px] text-sm sm:text-lg"

  return (
    <main className="flex h-full md:w-full flex-col items-start justify-center text-left m-0 px-6 md:px-6 lg:px-6 xl:px-0">
      <div className='w-full md:w-2/3 lg:w-1/2'>
        <h2 className="text-4xl sm:text-center md:text-left md:text-6xl font-light  text-blue-100 mb-8">Something went wrong!</h2>
        <div className="flex gap-4 flex-col sm:flex-row">
          <Button onClick={goBack} mode="secondary" className={buttonClasses}>
            Go back
          </Button>
          <Button onClick={reset} className={buttonClasses}>
            Try again
          </Button>
        </div>
      </div>
    </main>
  );
}
